import React from "react"
import Layout from "../share/layout"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useLocation } from "@reach/router"
import ERROR_IMG from '../../images/ERROR.jpg'
import arrow_red_back from '../../images/arrow_red.png'
import ControlHomePREV from '../../images/arrow_prev.png'
import ControlHomeNEXT from '../../images/arrow_next.png'
import { Link } from 'gatsby'
import Image from 'gatsby-image';
import ShareButton from "../share/ShareButton";
import { BLOCKS } from '@contentful/rich-text-types'

const Exposition3slider = ({ pageContext, data }) => {
	let location = useLocation();

	var normalize = (function () {
		var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,",
			to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
			mapping = {};

		for (var i = 0, j = from.length; i < j; i++)
			mapping[from.charAt(i)] = to.charAt(i);

		return function (str) {
			var ret = [];
			for (var i = 0, j = str.length; i < j; i++) {
				var c = str.charAt(i);
				if (mapping.hasOwnProperty(str.charAt(i)))
					ret.push(mapping[c]);
				else if ((str[i] === " "))
					ret.push('-');
				else if ((str.length - 1 === i) && (str[str.length - 1] === " "))
					ret.push('-');
				else
					ret.push(c);
			}
			return ret.join('');
		}
	})();

	const clickSliderPREV = () => {
		document.querySelector('.control-prev').click()
	}
	const clickSliderNEXT = () => {
		document.querySelector('.control-next').click()
	}

	const options = {
		renderNode: {
			[BLOCKS.HEADING_1]: (node, children) => {
				return <h5>{children}</h5>
			},
		},
		renderMark: {},
	}

	return (
		<Layout
			descritpion={`${pageContext.datacarrusel.subtitulo}`}
			title={`${pageContext.datacarrusel.titulo}`}
			subtitile={`${pageContext.datacarrusel.titulo} ${pageContext.datacarrusel.subtitulo}`}
			imgurl={pageContext.datacarrusel.primeraImagen.file.url}
			metaTitle={pageContext.datacarrusel.metaTitle}
			metaKeywords={pageContext.datacarrusel.metaKeywords}
			metaDescripcin={pageContext.datacarrusel.metaDescripcin}
		>
			<div className="exposicion-slider-container">
				<ShareButton url={location.pathname} />
				<div className="button-back">
					<Link to={`/galeria/${normalize(pageContext.datacarrusel.idParaEnlazar).toLocaleLowerCase().trim()}`}><button>
						<img src={arrow_red_back} alt="imagen de flecha" />
						<p>VOLVER AL INICIO</p>
					</button></Link>
				</div>
				<button onClick={clickSliderPREV} className="button-control-prev"><img className="button-control-prev-icon" src={ControlHomePREV} alt="imagen de flecha" /></button>
				<button onClick={clickSliderNEXT} className="button-control-next"><img className="button-control-next-icon" src={ControlHomeNEXT} alt="imagen de flecha" /></button>
				<div className="outside-element-slider-container">
				</div>
				<h1 className="d-none">{pageContext.datacarrusel.titulo}</h1>
				{pageContext.datacarrusel.activar &&
					<Carousel className="carrusel-tipo-3" autoPlay infiniteLoop interval={8000} transitionTime={1000} stopOnHover={false} showThumbs={false}>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.primeraImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.primeraImagen.fluid} alt={pageContext.datacarrusel.primeraImagen.description ? pageContext.datacarrusel.primeraImagen.description : pageContext.datacarrusel.primeraImagen.title} /> :
								<img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.primeraDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.segundaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.segundaImagen.fluid} alt={pageContext.datacarrusel.segundaImagen.description ? pageContext.datacarrusel.segundaImagen.description : pageContext.datacarrusel.segundaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.segundaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.terceraImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.terceraImagen.fluid} alt={pageContext.datacarrusel.terceraImagen.description ? pageContext.datacarrusel.terceraImagen.description : pageContext.datacarrusel.terceraImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.terceraDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.cuartaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.cuartaImagen.fluid} alt={pageContext.datacarrusel.cuartaImagen.description ? pageContext.datacarrusel.cuartaImagen.description : pageContext.datacarrusel.cuartaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.cuartaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.quintaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.quintaImagen.fluid} alt={pageContext.datacarrusel.quintaImagen.description ? pageContext.datacarrusel.quintaImagen.description : pageContext.datacarrusel.quintaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.quintaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.sextaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.sextaImagen.fluid} alt={pageContext.datacarrusel.sextaImagen.description ? pageContext.datacarrusel.sextaImagen.description : pageContext.datacarrusel.sextaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.sextaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.septimaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.septimaImagen.fluid} alt={pageContext.datacarrusel.septimaImagen.description ? pageContext.datacarrusel.septimaImagen.description : pageContext.datacarrusel.septimaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.septimaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.octavaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.octavaImagen.fluid} alt={pageContext.datacarrusel.octavaImagen.description ? pageContext.datacarrusel.octavaImagen.description : pageContext.datacarrusel.octavaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.octavaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.novenaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.novenaImagen.fluid} alt={pageContext.datacarrusel.novenaImagen.description ? pageContext.datacarrusel.novenaImagen.description : pageContext.datacarrusel.novenaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.novenaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.decimaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.decimaImagen.fluid} alt={pageContext.datacarrusel.decimaImagen.description ? pageContext.datacarrusel.decimaImagen.description : pageContext.datacarrusel.decimaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.decimaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="img-exposion-gradient-container"></div>
							{pageContext.datacarrusel.onceavaImagen.fluid ?
								<Image className="img-exposicion-container" fluid={pageContext.datacarrusel.onceavaImagen.fluid} alt={pageContext.datacarrusel.onceavaImagen.description ? pageContext.datacarrusel.onceavaImagen.description : pageContext.datacarrusel.onceavaImagen.title} />
								: <img className="img-exposicion-container" src={ERROR_IMG} alt="imagen de error"></img>
							}
							<div className="carousel-caption">
								<div className="carousel-caption-text-container slider-caption-3">
									<div className="carousel-caption-tittle-container slider-type-3">
										<div>
											<h3 className="title-carusel-3">{pageContext.datacarrusel.titulo}</h3>
											<h2 className="subtitle-carusel-3">{pageContext.datacarrusel.subtitulo}</h2>
										</div>
									</div>
									<div className="vertical_line"></div>
									<div className="carousel-caption-subtittle-container carrusel-3">
										{documentToReactComponents(pageContext.datacarrusel.onceavaDescripcion.json, options)}
									</div>
								</div>
							</div>
						</div>
					</Carousel>
				}
			</div>
		</Layout>
	)
}
export default Exposition3slider



